* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 36px;
  line-height: 54px;
  font-weight: 800;
}

h2 {
  font-size: 30px;
  line-height: 45px;
  font-weight: 800;
}

h3 {
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
}

h4 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
}

h5 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

h6 {
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
}

@keyframes shimmer {
  0% {
    background-position: -400px 0;
  }

  100% {
    background-position: 400px 0;
  }
}

.shimmer {
  background: #f3f5f9;
  background-image: linear-gradient(
    to right,
    rgb(243, 245, 249) 0%,
    rgb(250, 250, 250) 35%,
    rgb(250, 250, 250) 40%,
    rgb(243, 245, 249) 100%
  );
  background-repeat: no-repeat;
  background-size: 400px 300px;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: shimmer;
  -webkit-animation-timing-function: linear;
}
